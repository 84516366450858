$(function () {});

function device() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (
    window.innerWidth < 769 ||
    (ua.indexOf("iPhone") > 0 && ua.indexOf("iPad") == -1) ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    return "sp";
    // } else if((window.innerWidth < 769) || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
  } else if (window.innerWidth < 992) {
    return "tb";
  } else {
    return "pc";
  }
}

$(window).on("load resize orientationchange", function (event) {
  if (device() == "pc") {
    var header_h = $("header").outerHeight();
    var banner_h = $('top-banner').outerHeight();
    $("main").addClass("full");
    $("#mv").css({
      marginTop: -header_h,
      height: $(window).height(),
    });
    $("#mv .centering img").css("top", $(window).height() / 2 + header_h / 2);
  } else {
    $("#mv").removeClass("full").removeAttr("style");
  }
});

// prev_pos = $(window).scrollTop();

// $(window).on("scroll", function (event) {
//   var timer = null;
//   var current_pos = $(this).scrollTop();
//   if (device() == "pc") {
//     var header_h = $("header").outerHeight();
//     var half = $(window).outerHeight() / 2;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       if (current_pos > prev_pos) {
//         // 下方向の処理
//         if (current_pos > 1 && current_pos < $("#contents").offset().top) {
//           $("html, body")
//             .not(":animated")
//             .animate({ scrollTop: $("#contents").offset().top }, 600, "swing");
//         }
//       } else if (current_pos < prev_pos) {
//         // 上方向の処理
//         if (current_pos < $("#contents").offset().top - header_h) {
//           $("html, body")
//             .not(":animated")
//             .animate({ scrollTop: 0 }, 600, "swing");
//         }
//       }
//       prev_pos = current_pos;
//     }, 200);
//   }
// });
